import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Url } from 'utils/urls';
import { IsLoggedIn } from 'utils/user';
import { Modal } from 'components/Modal';
import { Button } from 'components';
import Illustration from 'components/Illustration/Illustration';
import { IModal } from 'components/Modal/Modal';

const IllustrationWrapper = styled.div`
  margin-bottom: 14px;
`;

const Error = (props: IModal) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Modal {...props}>
      <Grid
        container
        flexWrap="nowrap"
        alignItems="center"
        maxWidth="253px"
        textAlign="center"
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <IllustrationWrapper>
            <Illustration width={44} component="exclamationMark" />
          </IllustrationWrapper>
          <DialogTitle
            sx={{
              paddingBottom: '10px'
            }}
          >
            <Trans i18nKey="MODAL_ERROR_TITLE" />
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="body2"
              sx={{
                width: '250px',
                marginBottom: '5px'
              }}
            >
              <Trans i18nKey="MODAL_ERROR_MESSAGE">
                Wystąpił nieoczekiwany błąd. Spróbuj ponownie, a jeśli sytuacja
                będzie się powtarzała, koniecznie
                <Link
                  onClick={() => {
                    navigate(
                      IsLoggedIn() ? Url.loggedContact : Url.unloggedContact
                    );
                    if (props.onClose) {
                      props.onClose({}, 'escapeKeyDown');
                    }
                  }}
                  variant="body2"
                  sx={{ fontWeight: 700, cursor: 'pointer' }}
                >
                  skontaktuj się z nami
                </Link>
                . Chętnie Ci pomożemy.
              </Trans>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              type="button"
              size="large"
              label={t('MODAL_UNDERSTAND_TEXT')}
              onClick={() =>
                props.onClose && props.onClose({}, 'escapeKeyDown')
              }
            />
          </DialogActions>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default Error;
