import React, { useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RootDispatch, RootState } from 'storage/store';
import useInstallPrompt from 'hooks/useInstallPrompt';
import HowInstallPWA from 'components/Modal/modals/HowInstallPWA';
import { Icon } from 'components/Icon';

enum ButtonType {
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E'
}

const PWAButton = () => {
  const { t } = useTranslation();
  const [PWAModalVisible, setPWAModalVisible] = useState(false);
  const dispatch = useDispatch<RootDispatch>();
  const { isIphone } = useSelector((state: RootState) => state.pwa);
  const [promptToInstall] = useInstallPrompt();

  const onPWAButtonClick = (
    buttonType: string,
    event: MouseEvent<HTMLElement>
  ) => {
    event.preventDefault();
    dispatch.pwa.recordButtonClick(buttonType);
    if (isIphone) {
      return setPWAModalVisible(true);
    }
    return promptToInstall();
  };

  return (
    <>
      <HowInstallPWA
        open={PWAModalVisible}
        onClose={() => setPWAModalVisible(false)}
      />
      <Button
        id="pwaBtn"
        variant="contained"
        onClick={(event) => onPWAButtonClick(ButtonType.E, event)}
        size="medium"
        sx={{
          textAlign: 'left',
          padding: '14px 25px 15px 17px',
          justifyContent: 'left',
          width: '328px'
        }}
      >
        <Icon
          icon="cursor"
          fontSize="medium"
          sx={{
            height: '16px',
            width: '16px',
            marginRight: '15px'
          }}
        />
        {t('PWA_APP_BUTTON_2')}
      </Button>
    </>
  );
};

export default PWAButton;
