export enum Url {
  address = '/dashboard/user-profile/address',
  case = '/dashboard/case',
  changePassword = '/dashboard/user-profile/change-password',
  dashboard = '/dashboard',
  dynamicRemission = '/dynamic-remission',
  error = '/error',
  faq = '/faq',
  informationObligation = '/information-obligation',
  loggedContact = '/dashboard/contact',
  login = '/login',
  main = '/',
  marketingConsents = '/marketing-consents',
  newEmail = '/dashboard/user-profile/email',
  notFound = '/not-found',
  offer = '/offer',
  success = '/success',
  reject = '/reject',
  partialRemission = '/partial-remission',
  payment = '/payment',
  paymentSuccess = '/dashboard/case/payment/done/success',
  paymentError = '/dashboard/case/payment/done/error',
  paymentPending = '/dashboard/case/payment/done/pending',
  phone = '/dashboard/user-profile/phone',
  plusSettlement = '/plus-settlement',
  plusSettlementTimeout = '/plus-settlement/timeout',
  privacyPolicy = '/privacy-policy',
  register = '/register',
  registerSuccess = '/register/success',
  registerLinkExpired = '/register/expired',
  registerLinkUsed = '/register/used',
  resetPassword = '/reset-password',
  resetPasswordLinkExpired = '/reset-password/expired',
  resetPasswordLinkUsed = '/reset-password/used',
  resetPasswordChangeSuccess = '/reset-password/change-success',
  resetPasswordRequestSuccess = '/reset-password/request-success',
  settlementZero = '/settlement-zero',
  settlementZeroAnnex = '/settlement-zero-annex',
  settlementZeroWithPrerequisite = '/settlement-zero-with-prerequisite',
  settlementZeroTimeout = '/settlement-zero/timeout',
  contractGeneration = '/contract-generation',
  summaryWithContract = '/summary-with-contract',
  technicalBreak = '/technical-break',
  totalRepayment = '/total-repayment',
  unblockAccount = '/unblock-account',
  unblockAccountLinkExpired = '/unblock-account/expired',
  unblockAccountLinkUsed = '/unblock-account/used',
  unblockAccountSuccess = '/unblock-account/unblock-success',
  unloggedContact = '/contact',
  userProfile = '/dashboard/user-profile'
}

export enum OldUrl {
  faces = '/faces',
  facesAccount = '/faces/account',
  facesAccountUnblock = '/faces/account/unblock',
  rsAccount = '/rs/account',
  rsAccountUnblock = '/rs/account/unblock',
  home = '/faces/home',
  passwordReset = '/faces/password-reset'
}
