import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RootDispatch } from 'storage/store';
import { IBeforeInstallPromptEvent } from '../storage/pwa/pwa.model';

const useHandlePrompt = (
  prompt: IBeforeInstallPromptEvent | null,
  isLoggedIn: boolean | null | string
) => {
  const dispatch = useDispatch<RootDispatch>();

  useEffect(() => {
    const isAppInstalled = async () => {
      if (prompt) {
        try {
          const userChoice = await prompt.userChoice;
          if (userChoice.outcome === 'accepted') {
            dispatch.pwa.setPWAButton(false);
          }
        } catch (error) {
          throw new Error('User declined to install');
        }
      }
    };

    isAppInstalled();
  }, [prompt]);

  useEffect(() => {
    if (prompt) {
      dispatch.pwa.setPrompt(prompt);
    }
  }, [prompt, isLoggedIn]);

  useEffect(() => {
    if (prompt !== null) {
      dispatch.pwa.setPWAButton(true);
    }
  }, [prompt]);
};

export default useHandlePrompt;
