import React from 'react';
import { SvgIcon } from '@mui/material';

interface ILogo {
  size?: number;
}

const Logo = ({ size, ...props }: ILogo) => {
  const defaultSize = 30;
  return (
    <SvgIcon
      {...props}
      sx={{ fontSize: size ?? defaultSize, verticalAlign: 'middle' }}
      viewBox="0 0 155 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="logo svg"
    >
      <g clipPath="url(#clip0_1257_2)">
        <path
          d="M0.372596 1.46097C7.69249 1.6995 14.908 1.4796 21.9894 2.29582C29.559 3.16795 33.0661 7.14097 33.5954 15.3814C34.065 22.7796 33.7258 30.2448 33.4463 37.6691C33.2935 41.7688 31.3778 45.153 27.6545 47.2364C27.7104 47.4116 27.7141 47.5756 27.7812 47.6091C32.6674 50.0205 34.3893 54.355 34.4899 59.3753C34.6613 67.2468 34.6526 75.1208 34.4638 82.9973C34.3425 85.3454 33.839 87.6579 32.973 89.8438C31.2809 94.4281 27.3824 96.5935 22.8914 96.9252C15.4373 97.4954 7.94966 97.4619 0.331604 97.6892L0.372596 1.46097ZM12.7799 53.5761V85.7888C13.6819 85.7888 14.4235 85.8224 15.1652 85.7888C19.1569 85.5689 21.0725 84.3726 21.2254 80.7014C21.5347 73.2958 21.5012 65.8567 21.1955 58.4473C21.0576 55.0147 19.2649 53.8258 15.7727 53.6022C14.7999 53.5202 13.8198 53.5761 12.7799 53.5761ZM12.7054 13.6745V41.4893C17.0324 42.477 20.1594 40.8222 20.4054 36.7001C20.7557 30.5928 20.7557 24.4704 20.4054 18.3631C20.2153 14.8149 18.6239 13.8161 14.9639 13.6782C14.2744 13.6521 13.5999 13.6745 12.7054 13.6745Z"
          fill="#125545"
        />
        <path
          d="M95.2257 79.7883C95.1922 76.1433 95.2257 72.4945 95.2257 68.5811C91.1781 68.5811 87.384 68.5774 83.5862 68.5997C83.2321 68.6035 82.878 68.6706 82.5575 68.7041C81.9463 71.8236 82.3264 84.8682 83.1017 88.2226C84.5962 94.7076 87.7046 97.7116 94.1672 98.8297C95.5774 99.0344 97.003 99.1142 98.4272 99.0682C106.698 99.0123 111.878 95.0132 114.196 87.0672C115.646 82.0953 115.18 77.0005 115.423 71.9354C115.762 64.7721 113.652 58.4138 109.865 52.4505C106.481 47.1134 103.466 41.5452 99.9031 36.3274C95.5872 30.0212 95.4679 23.1262 96.1761 15.9964C96.256 15.3212 96.4033 14.6557 96.6159 14.0099C97.052 12.616 98.1179 12.1464 99.4783 12.1836C100.839 12.2209 101.562 12.724 101.934 14.1627C102.136 14.9412 102.101 15.7526 102.147 16.5554V29.3243H103.854C107.137 29.2684 110.425 29.3019 113.708 29.3243H114.39V12.0308C113.909 7.78573 112.016 4.08107 107.916 1.96784C98.3937 -2.97048 87.2387 1.79639 84.257 12.0308C82.9377 16.5741 83.2955 21.2291 83.1017 25.8656C82.8408 32.0413 84.5552 37.5722 87.7828 42.7677C91.4726 48.7123 94.9574 54.7911 98.6844 60.7096C103.366 68.1227 102.985 76.1433 101.789 84.2719C101.48 86.3553 100.298 87.0038 98.4123 86.933C96.5264 86.8622 95.8518 85.5987 95.5984 84.0371C95.3719 82.6317 95.2474 81.2117 95.2257 79.7883Z"
          fill="#125545"
        />
        <path
          d="M58.3802 85.0733V53.6097H71.2758V52.8643C71.3652 49.1149 71.3391 45.3543 71.3391 41.3105H58.466V14.1963H72.1293V8.95983V1.81511H45.6114V97.3315H72.8933C72.8933 93.5188 72.8672 89.8663 72.8933 86.2175V85.0733H63.9708H58.3802Z"
          fill="#125545"
        />
        <path
          d="M131.926 97.3837H144.747L144.762 14.0919H154.646V1.79272H121.941V14.1255H131.926V97.3837Z"
          fill="#125545"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M154.672 115.538C154.672 113.068 152.669 111.066 150.199 111.066H4.47244C2.00238 111.066 0 113.068 0 115.538V126.533V137.528C0 139.998 2.00238 142 4.47244 142H150.199C152.669 142 154.672 139.998 154.672 137.528V126.533V115.538Z"
        fill="#C00D0D"
      />
      <path
        d="M24.5665 118.278C21.0124 118.278 19.2711 122.071 19.2711 126.46C19.2711 130.873 20.7023 134.737 24.3518 134.737C27.405 134.737 29.5518 132.28 29.5518 126.221C29.5518 122.548 28.4545 118.278 24.5665 118.278ZM24.4472 120.997C25.8545 120.997 26.2839 123.55 26.2839 126.364C26.2839 129.632 25.783 132.017 24.4711 132.017C23.2069 132.017 22.5151 130.157 22.5151 126.507C22.5151 123.049 23.2069 120.997 24.4472 120.997Z"
        fill="white"
      />
      <path
        d="M45.7347 134.546V130.92C45.7347 127.7 45.6155 125.219 45.5439 123.597H45.5916C45.9494 125.052 46.6889 127.08 47.2136 128.44L49.6705 134.546H52.5328V118.469H49.7897V122.023C49.7897 124.981 50.0044 127.772 50.0998 129.465H50.0521C49.5989 128.082 48.8118 126.007 48.3347 124.766L45.854 118.469H42.9916V134.546H45.7347Z"
        fill="white"
      />
      <path
        d="M66.7403 134.546H73.9916V131.898H69.865V118.469H66.7403V134.546Z"
        fill="white"
      />
      <path
        d="M87.2511 118.469V134.546H90.3758V118.469H87.2511Z"
        fill="white"
      />
      <path
        d="M107.36 134.546V130.92C107.36 127.7 107.241 125.219 107.169 123.597H107.217C107.575 125.052 108.314 127.08 108.839 128.44L111.296 134.546H114.158V118.469H111.415V122.023C111.415 124.981 111.63 127.772 111.725 129.465H111.678C111.224 128.082 110.437 126.007 109.96 124.766L107.48 118.469H104.617V134.546H107.36Z"
        fill="white"
      />
      <path
        d="M135.164 124.885H131.491V121.188H135.355V118.469H128.366V134.546H135.641V131.827H131.491V127.485H135.164V124.885Z"
        fill="white"
      />
      <defs>
        <clipPath id="clip0_1257_2">
          <rect
            width="154.299"
            height="99.1391"
            fill="white"
            transform="translate(0.372681)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Logo;
