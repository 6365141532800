import { API } from 'utils';
import {
  DocumentResponse,
  RejectResponse,
  AcceptSettlementResponse,
  AvailableDocumentsResponse,
  DownloadDocumentResponse,
  AvailableDocuments,
  SharedDocumentsResponse,
  SharedDocument,
  SharedDocumentsForUserResponse,
  DocumentsForOrderResponse,
  DocumentForOrder
} from './documents.model';
import { RootDispatch } from '../store';
import { UserAcceptanceDataResponse } from '../user/user.model';

const endpoint = '/document';

const effects = (dispatch: RootDispatch) => ({
  async getSharedDocument({
    agreementId,
    barcode,
    displayName
  }: {
    agreementId: number;
    barcode: string;
    displayName: string;
  }): Promise<string | undefined> {
    return API.get<void, DownloadDocumentResponse>(
      `${endpoint}/shared/${agreementId}/download/${barcode}?documentName=${displayName}`
    )
      .then((response) => response.data.file)
      .catch(() => undefined);
  },
  async getSharedDocumentsForAgreement(
    agreementId: number
  ): Promise<SharedDocument[] | null | undefined> {
    return API.get<void, SharedDocumentsResponse>(
      `${endpoint}/shared/${agreementId}`
    )
      .then((response) => {
        if (response.success) {
          dispatch.documents.storeSharedDocumentsForAgreement(response.data);
          return response.data;
        }
        return null;
      })
      .catch(() => undefined);
  },
  async getDocumentsForOrder(
    agreementId: number
  ): Promise<DocumentForOrder[] | null | undefined> {
    return API.get<void, DocumentsForOrderResponse>(
      `${endpoint}/orderable/${agreementId}`
    )
      .then((response) => {
        if (response.success) {
          dispatch.documents.storeDocumentsForOrder(response.data);
          return response.data;
        }
        return null;
      })
      .catch(() => undefined);
  },
  async getSharedDocumentsForUser() {
    return API.get<void, SharedDocumentsForUserResponse>(`${endpoint}/shared`)
      .then((response) => {
        if (response.success) {
          dispatch.documents.storeSharedDocumentsForUser(response.data);
          return response.data;
        }
        return null;
      })
      .catch(() => undefined);
  },
  async getDocument(agreementId: number): Promise<string | null | undefined> {
    return API.get<void, DocumentResponse>(`${endpoint}/${agreementId}`)
      .then((response) => {
        if (response.success) {
          return response.data.file;
        }
        return null;
      })
      .catch(() => undefined);
  },
  // TODO: Add types for the response
  async rejectDocument(agreementId: number): Promise<any | undefined> {
    return API.delete<any, RejectResponse>(
      `${endpoint}/reject/${agreementId}`,
      []
    )
      .then((response) => response)
      .catch((error) => error);
  },
  async acceptDocument(agreementId: number): Promise<any | undefined> {
    return API.post<any, AcceptSettlementResponse>(
      `${endpoint}/accept/${agreementId}`
    )
      .then((response) => response)
      .catch((error) => error);
  },
  async getAvailableDocuments(
    agreementId: number
  ): Promise<AvailableDocuments[] | undefined> {
    return API.get<void, AvailableDocumentsResponse>(
      `${endpoint}/downloadable/${agreementId}`
    )
      .then((response) => {
        dispatch.documents.storeAvailableDocuments(response.data);
        return response.data;
      })
      .catch(() => undefined);
  },
  async getDMSDocument({
    agreementId,
    barcode
  }: {
    agreementId: number;
    barcode: string;
  }): Promise<string | undefined> {
    return API.get<void, DownloadDocumentResponse>(
      `${endpoint}/${agreementId}/${barcode}`
    )
      .then((response) => response.data.file)
      .catch(() => undefined);
  },
  async updateDocumentAccesibility({
    barcode,
    agreementId
  }: {
    barcode: string;
    agreementId: number;
  }) {
    const url = `${endpoint}/request?agreementId=${agreementId}${
      barcode ? `&barcode=${barcode}` : ''
    }`;
    return API.patch<void, UserAcceptanceDataResponse>(url)
      .then((response) => response)
      .catch((error) => error);
  }
});

export default effects;
