import { MouseEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Box,
  ClickAwayListener,
  Menu as MuiMenu,
  Slide,
  SwipeableDrawer,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useSelector } from 'react-redux';
import theme from 'App.theme';
import { RootState } from 'storage/store';
import { Agreement } from 'storage/agreements/agreements.model';
import { SharedDocumentForUser } from 'storage/documents/documents.model';
import { Constant } from 'utils/consts';
import getRecentlyPaidAgreements from 'pages/Dashboard/utils/getRecentlyPaidAgreements';
import { Icon } from 'components/Icon';
import { DesktopMenuAnchorContext } from 'components/Menu/utils/desktopMenuAnchorContext';
import { MenuVisibilityContext } from 'components/Menu/utils/menuVisibilityContext';
import sortNotifications from '../../utils/sortNotifications';
import OverduePaymentsNotifications from './OverduePayments';
import PaymentsDone from './PaymentsDone';
import SharedDocuments from './SharedDocuments';

const BadgeAndMenu = () => {
  const { t } = useTranslation();
  const tabletOrBiggerDevice = useMediaQuery(theme.breakpoints.up('md'));
  const { agreements } = useSelector((state: RootState) => state.agreements);
  const notifications = useSelector((state: RootState) => state.notifications);
  const { sharedDocumentsForUser: sharedDocumentsInitial } = useSelector(
    (state: RootState) => state.documents
  );
  const [sharedDocumentsForUser, setSharedDocumentsForUser] = useState<
    SharedDocumentForUser[]
  >([]);
  const notificationArray = sortNotifications(notifications);
  const [recentlyPaidAgreements, setRecentlyPaidAgreements] = useState<
    null | Agreement[]
  >(null);
  const [isNewNotificationAvailable, setIsNewNotificationAvailable] =
    useState(false);
  const { notificationAnchor, setNotificationAnchor } = useContext(
    DesktopMenuAnchorContext
  );
  const { notificationsVisible, setNotificationsVisible, setVisible } =
    useContext(MenuVisibilityContext);
  const notificationsArray = sortNotifications(notifications);
  if (!setNotificationAnchor || !setVisible) return null;

  useEffect(() => {
    if (sharedDocumentsInitial) {
      setSharedDocumentsForUser(sharedDocumentsInitial);
    }
  }, [sharedDocumentsInitial]);

  useEffect(() => {
    if (!agreements) {
      return;
    }
    setRecentlyPaidAgreements(() =>
      getRecentlyPaidAgreements(agreements, true)
    );
  }, [agreements]);

  useEffect(() => {
    if (
      Object.entries(notifications).length === Constant.zero &&
      recentlyPaidAgreements?.length === Constant.zero &&
      sharedDocumentsForUser.length === Constant.zero
    ) {
      setIsNewNotificationAvailable(false);
    }
    const isAnyNewPaymentNotification =
      recentlyPaidAgreements &&
      recentlyPaidAgreements.some((agreement) => {
        const paymentsDone = localStorage.getItem('paymentsDone');
        const parsedData = paymentsDone && JSON.parse(paymentsDone);
        return (
          agreement.generalData.lastSettledPayment.onDashboard &&
          (!parsedData ||
            !parsedData.includes(agreement.generalData.agreementId))
        );
      });
    const isAnyNewSharedDocumentNotification =
      sharedDocumentsForUser &&
      sharedDocumentsForUser.some((document) => {
        const sharedDocuments = localStorage.getItem('sharedDocuments');
        const parsedData = sharedDocuments && JSON.parse(sharedDocuments);

        return (
          document.onDashboard &&
          (!parsedData || !parsedData.includes(+document.barcode))
        );
      });

    if (
      Object.entries(notifications).length > Constant.zero ||
      isAnyNewPaymentNotification ||
      isAnyNewSharedDocumentNotification
    ) {
      setIsNewNotificationAvailable(true);
    } else {
      setIsNewNotificationAvailable(false);
    }
  }, [Object.entries(notifications), recentlyPaidAgreements]);

  const isEmptyNotificationsList =
    notificationsArray.length === Constant.zero &&
    recentlyPaidAgreements?.length === Constant.zero &&
    sharedDocumentsForUser.length === Constant.zero;

  if (tabletOrBiggerDevice) {
    return (
      <>
        <ClickAwayListener onClickAway={() => setNotificationAnchor(null)}>
          <Badge
            color="warning"
            invisible={!isNewNotificationAvailable}
            variant="dot"
            overlap="circular"
            sx={{ marginRight: { md: '30px', lg: 0 }, cursor: 'pointer' }}
            onClick={(event: MouseEvent<HTMLElement>) => {
              setNotificationAnchor(
                notificationAnchor ? null : event.currentTarget
              );
              setVisible(false);
            }}
          >
            <Icon icon="bell" color="secondary" />
          </Badge>
        </ClickAwayListener>
        <MuiMenu
          anchorEl={notificationAnchor}
          elevation={2}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={Boolean(notificationAnchor)}
          onClose={() => setNotificationAnchor(null)}
          sx={{
            display: { xs: 'none', md: 'block' },
            padding: '10px',
            marginTop: '22px',
            maxWidth: '425px',
            zIndex: 1300
          }}
          TransitionComponent={Slide}
          disableAutoFocusItem
          disableScrollLock
          keepMounted
        >
          {isEmptyNotificationsList ? (
            <Box
              sx={{
                minWidth: '392px',
                padding: '40px 20px 30px'
              }}
            >
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {t('NOTIFICATIONS_EMPTY_LIST')}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ marginTop: { xs: 0, lg: '25px' } }}>
              {notificationArray ? (
                <OverduePaymentsNotifications
                  isMoreThanOneNotificationMore={
                    (recentlyPaidAgreements &&
                      recentlyPaidAgreements.length > Constant.zero) ||
                    sharedDocumentsForUser.length > Constant.zero
                  }
                  notificationArray={notificationArray}
                />
              ) : null}
              {recentlyPaidAgreements ? (
                <PaymentsDone
                  agreements={recentlyPaidAgreements}
                  isMoreThanOneNotificationMore={
                    sharedDocumentsForUser.length > Constant.zero
                  }
                />
              ) : null}
              {sharedDocumentsForUser.length > Constant.zero ? (
                <SharedDocuments sharedDocuments={sharedDocumentsForUser} />
              ) : null}
            </Box>
          )}
        </MuiMenu>
      </>
    );
  }

  return (
    <>
      <Badge
        color="warning"
        variant="dot"
        overlap="circular"
        invisible={!isNewNotificationAvailable}
        sx={{ marginRight: '30px', cursor: 'pointer' }}
        onClick={() => {
          setNotificationsVisible!(!notificationsVisible);
          setVisible(false);
        }}
      >
        <Icon icon="bell" color="secondary" />
      </Badge>
      <SwipeableDrawer
        disableBackdropTransition
        anchor="top"
        open={notificationsVisible}
        onClose={() => setNotificationsVisible!(false)}
        onOpen={() => setNotificationsVisible!(true)}
        BackdropProps={{
          invisible: true
        }}
        disableScrollLock
      >
        <Box
          padding={{
            xs: '24px 0 70px',
            md: '36px 49px'
          }}
        >
          {isEmptyNotificationsList ? (
            <Box
              sx={{
                minWidth: '392px',
                padding: '40px 20px 30px'
              }}
            >
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {t('NOTIFICATIONS_EMPTY_LIST')}
              </Typography>
            </Box>
          ) : (
            <div>
              {notificationArray ? (
                <OverduePaymentsNotifications
                  isMoreThanOneNotificationMore={
                    (recentlyPaidAgreements &&
                      recentlyPaidAgreements.length > Constant.zero) ||
                    sharedDocumentsForUser.length > Constant.zero
                  }
                  notificationArray={notificationArray}
                />
              ) : null}
              {recentlyPaidAgreements ? (
                <PaymentsDone
                  agreements={recentlyPaidAgreements}
                  isMoreThanOneNotificationMore={
                    sharedDocumentsForUser.length > Constant.zero
                  }
                />
              ) : null}
              {sharedDocumentsForUser.length > Constant.zero ? (
                <SharedDocuments sharedDocuments={sharedDocumentsForUser} />
              ) : null}
            </div>
          )}
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default BadgeAndMenu;
