import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import reducers from './application.reducers';
import effects from './application.effects';
import { ApplicationState } from './application.model';

const initialState: ApplicationState = {
  bffVersion: '',
  adapterVersion: '',
  sigmaAvailable: true,
  eodState: { date: '', status: null }
};

const applicationModel = createModel<RootModel>()({
  state: initialState,
  reducers,
  effects
});

export default applicationModel;
