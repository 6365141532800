import { EodState } from 'storage/application/application.model';
import { setEodInfoStatusToLocalStorage } from './setEodInfoStatusToLocalStorage';
import { getEodInfoFromLocalStorage } from './getEodInfoFromLocalStorage';
import { handleEodInfoDateComparison } from './handleEodInfoDateComparison';

export enum EodInfoStatus {
  NOT_DISPLAYED = 'NOT_DISPLAYED',
  CLOSED = 'CLOSED',
  DISPLAYED = 'DISPLAYED'
}
export interface EodInfoInLocalStorageState {
  status: EodInfoStatus;
  date: string;
}

export const EodName = 'eod';

export const updateEodInfoInLocalStorage = (newStatus: EodState) => {
  const previousInfo = getEodInfoFromLocalStorage();
  switch (previousInfo.status) {
    case EodInfoStatus.NOT_DISPLAYED:
      if (newStatus.status === true) {
        setEodInfoStatusToLocalStorage(EodInfoStatus.DISPLAYED, newStatus.date);
      }
      break;
    case EodInfoStatus.DISPLAYED:
      if (newStatus.status === false) {
        localStorage.removeItem(EodName);
      } else {
        handleEodInfoDateComparison(previousInfo.date, newStatus.date);
      }
      break;
    case EodInfoStatus.CLOSED:
      if (newStatus.status === false) {
        localStorage.removeItem(EodName);
      } else {
        handleEodInfoDateComparison(previousInfo.date, newStatus.date);
      }
      break;
    default:
  }
};
