import { API } from 'utils';
import {
  AgreementResponse,
  Agreements,
  AgreementsResponse,
  PaymentDetailsResponse,
  PaymentDetails,
  AgreementsWithProductAvailabilityResponse,
  AgreementsWithProductAvailability
} from './agreements.model';
import { RootDispatch } from '../store';

const endpoint = '/agreements';
const effects = (dispatch: RootDispatch) => ({
  async getAllAgreements(audit?: boolean): Promise<Agreements | undefined> {
    const isAudit = audit !== undefined ? audit : true;
    return API.get<undefined, AgreementsResponse>(
      `${endpoint}?audit=${isAudit}`
    )
      .then((response) => {
        dispatch.agreements.storeAllAgreements(
          response.data.agreements,
          response.data.totalDebtAmountOnAllAgreementsByCurrency
        );
        return response.data;
      })
      .catch((error) => error);
  },
  async getAgreement({
    agreementId,
    audit
  }: {
    agreementId: number;
    audit?: boolean;
  }): Promise<AgreementResponse | undefined> {
    const isAudit = audit !== undefined ? audit : true;
    return API.get<undefined, AgreementResponse>(
      `${endpoint}/${agreementId}?audit=${isAudit}`
    )
      .then((response) => {
        dispatch.agreements.storeAgreementDetails(response.data, agreementId);
        return response;
      })
      .catch((error) => error);
  },
  async getPaymentDetails(
    agreementId: number
  ): Promise<PaymentDetails | undefined> {
    return API.get<undefined, PaymentDetailsResponse>(
      `${endpoint}/${agreementId}/paymentDetails`
    )
      .then((response) => response.data)
      .catch((error) => error);
  },
  async getAgreementsWithProductAvailability(): Promise<
    AgreementsWithProductAvailability | undefined
  > {
    return API.get<undefined, AgreementsWithProductAvailabilityResponse>(
      `${endpoint}/productAvailability`
    )
      .then((response) => {
        dispatch.agreements.storeAgreementsWithProductAvailability(
          response.data
        );
        return response.data;
      })
      .catch((error) => error);
  }
});

export default effects;
