import { ReactNode, useContext, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Notification } from 'storage/notifications/notifications.model';
import { Url } from 'utils/urls';
import { Constant } from 'utils/consts';
import getNotificationText from 'pages/Dashboard/pages/Main/components/Notifications/getNotificationText';
import { DesktopMenuAnchorContext } from '../../utils/desktopMenuAnchorContext';
import { MenuVisibilityContext } from '../../utils/menuVisibilityContext';
import MenuItem from '../../styled/MenuItem';
import { LinkStyled } from '../../styled/LinkStyled';
import NotificationItemWrapper from '../../styled/NotificationItemWrapper';
import NotificationItem from './NotificationItem';

const OverduePaymentsNotifications = ({
  isMoreThanOneNotificationMore,
  notificationArray
}: {
  isMoreThanOneNotificationMore: boolean;
  notificationArray: [string, Notification][];
}) => {
  const { t } = useTranslation();
  const [notificationsComponents, setNotificationsComponents] = useState<
    ReactNode[]
  >([]);
  const { setNotificationAnchor } = useContext(DesktopMenuAnchorContext);
  const { setNotificationsVisible } = useContext(MenuVisibilityContext);

  useEffect(() => {
    setNotificationsComponents(paymentsNotifications());
  }, [notificationArray]);

  const paymentsNotifications = useCallback(
    () =>
      notificationArray.map(
        (
          [
            agreementId,
            {
              amountAndDateData,
              type,
              variant,
              daysCount,
              isMultipleInstallmentsOverdue,
              paymentState
            }
          ],
          index
        ) => (
          <MenuItem
            onClick={() => {
              setNotificationsVisible!(false);
              setNotificationAnchor!(null);
            }}
            key={agreementId}
          >
            <LinkStyled
              to={`${Url.case}/${agreementId}${Url.payment}`}
              state={paymentState}
              data-link
              $isNotificationLink
            >
              <NotificationItemWrapper>
                <NotificationItem
                  icon="exclamationMarkCircle"
                  color={variant}
                  text={getNotificationText(
                    daysCount,
                    amountAndDateData,
                    type,
                    t,
                    isMultipleInstallmentsOverdue!
                  )}
                  linkText={t('NOTIFICATIONS_LINK_PAY')}
                  fontWeight={700}
                  withoutBorder={
                    !isMoreThanOneNotificationMore &&
                    index === notificationArray.length - Constant.one
                  }
                />
              </NotificationItemWrapper>
            </LinkStyled>
          </MenuItem>
        )
      ),
    [notificationArray]
  );

  return <>{notificationsComponents}</>;
};

export default OverduePaymentsNotifications;
