import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { events, apiEvents } from 'utils/Api/Api';
import { Url } from 'utils/urls';
import { RootDispatch, RootState } from 'storage/store';
import { MessageTypes } from 'storage/user/user.model';
import ErrorModal from 'components/Modal/modals/Error';

const GlobalErrors = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<RootDispatch>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { status: isEodProcessing } = useSelector(
    (state: RootState) => state.application?.eodState
  );
  const { pathname } = useLocation();
  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (isEodProcessing && pathname.includes(Url.offer)) {
      navigate(Url.dashboard);
    }
  };

  useEffect(() => {
    events.on(apiEvents.TOKEN_EXPIRED, () => {
      dispatch.user.logoutUser();
      navigate(Url.login);
    });

    events.on(apiEvents.GENERAL_ERROR, () => {
      if (pathname.includes(Url.offer)) {
        dispatch.application.getEodStatus();
      }
      setIsModalOpen(true);
    });
    events.on(apiEvents.NOT_FOUND, () =>
      navigate(Url.notFound, { replace: true })
    );
    events.on(apiEvents.ERROR, () => navigate(Url.error));
    events.on(apiEvents.SESSION_EXPIRED, async () => {
      await dispatch.user.logoutUser();
      dispatch.user.changeUserStatus(MessageTypes.sessionExpired);
      navigate(Url.login);
    });
  }, []);
  return <ErrorModal open={isModalOpen} onClose={handleCloseModal} />;
};

export default GlobalErrors;
