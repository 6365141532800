import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Agreement } from 'storage/agreements/agreements.model';
import { Url } from 'utils/urls';
import { formatCurrency } from 'utils/formatCurrency';
import { Constant } from 'utils/consts';
import checkNotificationFreshness from 'utils/checkNotificationFreshness';
import { DesktopMenuAnchorContext } from '../../utils/desktopMenuAnchorContext';
import { MenuVisibilityContext } from '../../utils/menuVisibilityContext';
import MenuItem from '../../styled/MenuItem';
import { LinkStyled } from '../../styled/LinkStyled';
import NotificationItemWrapper from '../../styled/NotificationItemWrapper';
import NotificationItem from './NotificationItem';

const PaymentsDone = ({
  agreements,
  isMoreThanOneNotificationMore
}: {
  agreements: Agreement[];
  isMoreThanOneNotificationMore: boolean;
}) => {
  const { t } = useTranslation();
  const [clickedPaymentDone, setClickedPaymentDone] = useState<number | null>(
    null
  );
  const { setNotificationAnchor } = useContext(DesktopMenuAnchorContext);
  const { setNotificationsVisible } = useContext(MenuVisibilityContext);

  const onItemClick = (agreementId: number) => {
    setNotificationsVisible!(false);
    setNotificationAnchor!(null);
    setClickedPaymentDone(agreementId);
    let arr = [agreementId];
    const notifications = localStorage.getItem('paymentsDone');
    if (notifications) {
      const notificationsArray = JSON.parse(notifications);
      if (!notificationsArray.includes(agreementId)) {
        arr = [...arr, ...notificationsArray];
      }
    }
    localStorage.setItem('paymentsDone', JSON.stringify(arr));
  };

  return (
    <>
      {agreements.map(({ generalData }, index) => {
        const { lastSettledPayment, agreementId } = generalData;
        const { amount, currency, onDashboard } = lastSettledPayment;
        const formattedCurrency = formatCurrency(
          'plain',
          amount,
          currency,
          true,
          true,
          true,
          true
        );
        if (typeof formattedCurrency !== 'string') {
          return null;
        }

        return (
          <MenuItem onClick={() => onItemClick(agreementId)} key={agreementId}>
            <LinkStyled
              to={`${Url.case}/${agreementId}#historySection`}
              data-link
              $isNotificationLink
            >
              <NotificationItemWrapper>
                <NotificationItem
                  icon="valid"
                  color="success"
                  text={t('DASHBOARD_RECENTLY_PAID', {
                    amount: formattedCurrency.replace(/ /, '\u00a0')
                  })}
                  linkText={t('NOTIFICATIONS_LINK_CHECK')}
                  fontWeight={
                    checkNotificationFreshness(
                      onDashboard,
                      generalData.agreementId,
                      'paymentsDone'
                    ) && clickedPaymentDone !== agreementId
                      ? Constant.sevenHundred
                      : Constant.sixHundred
                  }
                  withoutBorder={
                    !isMoreThanOneNotificationMore &&
                    index === agreements.length - Constant.one
                  }
                />
              </NotificationItemWrapper>
            </LinkStyled>
          </MenuItem>
        );
      })}
    </>
  );
};

export default PaymentsDone;
