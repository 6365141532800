import { useLayoutEffect, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { events, apiEvents } from 'utils/Api/Api';
import { RootDispatch, RootState } from 'storage/store';
import { Url } from 'utils/urls';
import { IsLoggedIn } from 'utils/user';
import { IRouteGuard, IRouteGuardState } from './RouteGuard.model';
import redirectFromOldUrls from './redirectFromOldUrls';

const RouteGuard = ({
  path,
  element,
  authenticatedOnly,
  unauthenticatedOnly,
  authorizedOnly
}: IRouteGuard) => {
  const { state } = useLocation() as {
    state: IRouteGuardState;
  };
  const dispatch = useDispatch<RootDispatch>();
  const { sigmaAvailable } = useSelector(
    (store: RootState) => store.application
  );
  useLayoutEffect(() => {
    if (path !== Url.technicalBreak) {
      dispatch.application.getSigmaAvailability();
    }
    dispatch.application.getEodStatus();
  }, []);

  useEffect(() => {
    if (path !== Url.technicalBreak) {
      events.on(apiEvents.SIGMA_TIMEOUT, () => {
        dispatch.user.logoutUser();
        window.location.pathname = Url.technicalBreak;
      });
    }
  }, []);

  useEffect(() => {
    if (!IsLoggedIn() && authenticatedOnly && element !== null) {
      events.emit(apiEvents.TOKEN_EXPIRED);
    }
  }, [IsLoggedIn()]);

  redirectFromOldUrls();
  if (
    path !== Url.technicalBreak &&
    path !== Url.unloggedContact &&
    path !== Url.faq &&
    !sigmaAvailable
  ) {
    if (IsLoggedIn()) {
      dispatch.user.logoutUser();
    }
    return <Navigate to={Url.technicalBreak} replace />;
  }
  if (path === '*') {
    return <Navigate to={Url.notFound} replace />;
  }

  if (IsLoggedIn() && (unauthenticatedOnly || element === null)) {
    return <Navigate to={Url.dashboard} />;
  }

  if (!IsLoggedIn() && authenticatedOnly) {
    return <Navigate to={Url.login} />;
  }

  if (authorizedOnly && !state?.hasAccess) {
    return <Navigate to={Url.notFound} replace />;
  }

  return <Outlet />;
};

export default RouteGuard;
