import { setEodInfoStatusToLocalStorage } from './setEodInfoStatusToLocalStorage';
import { EodInfoStatus } from './updateEodInfoInLocalStorage';

export const handleEodInfoDateComparison = (
  storedDate: string,
  receivedDate: string
) => {
  if (storedDate !== receivedDate) {
    setEodInfoStatusToLocalStorage(EodInfoStatus.DISPLAYED, receivedDate);
  }
};
