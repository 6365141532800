import { EodInfoStatus, EodName } from './updateEodInfoInLocalStorage';

export const setEodInfoStatusToLocalStorage = (
  status: EodInfoStatus,
  date: string
) => {
  localStorage.setItem(
    EodName,
    JSON.stringify({
      status,
      date
    })
  );
};
