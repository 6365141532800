import { useContext, useEffect, useState, lazy } from 'react';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  LiveChatWidget,
  useWidgetIsReady,
  useWidgetState
} from '@livechat/widget-react';
import isEmpty from 'lodash/isEmpty';
import * as Sentry from '@sentry/react';
import styled from 'styled-components/macro';
import { Container, Grid } from '@mui/material';
import getAppRoutes, { AppRoute } from 'App.routing';
import useHandlePrompt from 'hooks/useHandlePrompt';
import { Agreement } from 'storage/agreements/agreements.model';
import { Token } from 'utils/Token';
import { Constant } from 'utils/consts';
import setPaymentNotifications from 'utils/setPaymentNotifications';
import { trackVirtualPageView } from 'utils/trackVirtualPageView';
import { IsLoggedIn } from 'utils/user';
import { Url } from 'utils/urls';
import { webSocketsConnection } from 'utils/Api/Api';
import { PromptContext } from 'providers/PromptProvider';
import { RootDispatch, RootState } from 'storage/store';
import { Footer } from 'components/Footer';
import { GlobalErrors } from 'components/GlobalErrors';
import { Menu } from 'components/Menu';
import LastUrl from 'components/LastUrl';
import { RouteGuard, ScrollToTop } from 'components';
import { MenuVisibilityProvider } from '../Menu/utils/menuVisibilityContext';
import { WebSocketsEvents } from '../WebSocketsEvents';

const Impersonation = lazy(
  () => import('components/Impersonation/Impersonation')
);
const Statements = lazy(() => import('components/Statements/Statements'));
const Regulation = lazy(() => import('components/Regulation/Regulation'));

const LayoutContent = styled.div`
  display: flex;
  flex: 1;
  overflow-x: hidden;
`;

const StyledContainer = styled(Container)`
  flex: 1;
`;

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Layout = () => {
  const { t } = useTranslation();
  const isWidgetReady = useWidgetIsReady();
  const widgetState = useWidgetState();
  const { pathname } = window.location;
  const [settledCases, setSettledCases] = useState<Agreement[]>([]);
  const dispatch = useDispatch<RootDispatch>();
  const prompt = useContext(PromptContext);
  const user = useSelector((state: RootState) => state.user);
  const userId = useSelector((state: RootState) => state.user).id;
  const { step } = useSelector((state: RootState) => state.processStep);
  const { sigmaAvailable } = useSelector(
    (state: RootState) => state.application
  );
  const { agreements } = useSelector((state: RootState) => state.agreements);
  const agreementIds =
    (agreements &&
      agreements.map(({ generalData }) => generalData.agreementId)) ||
    [];
  const { sharedDocumentsForUser } = useSelector(
    (state: RootState) => state.documents
  );
  const liveChatLicense = process.env.REACT_APP_LIVE_CHAT_KEY;
  const { visibility } = useSelector((state: RootState) => state.liveChat);
  const isLoggedIn = IsLoggedIn();
  const showLiveChatWidget = liveChatLicense && isLoggedIn;
  const fullName = `${user.firstName} ${user.lastName}`;
  const isIphone = useSelector((state: RootState) => state.pwa.isIphone);
  const { username: tokenEmail } = Token.getTokenData();

  useHandlePrompt(prompt, isLoggedIn);

  useEffect(() => {
    if (!agreements && isLoggedIn) {
      loadAgreements();
    }
  }, [isLoggedIn, agreements]);

  const loadAgreements = async () => {
    await dispatch.agreements.getAllAgreements();
  };

  useEffect(() => {
    if (!sharedDocumentsForUser && isLoggedIn) {
      getSharedDocuments();
    }
  }, [isLoggedIn, sharedDocumentsForUser]);

  const getSharedDocuments = async () => {
    await dispatch.documents.getSharedDocumentsForUser();
  };

  useEffect(() => {
    if (!agreements || isEmpty(agreements) || !isLoggedIn) {
      return;
    }
    setSettledCases(() =>
      agreements
        ?.filter((agreement) => agreement.generalData.productData)
        .sort((a, b) => a.generalData.agreementId - b.generalData.agreementId)
    );
  }, [agreements]);

  useEffect(() => {
    if (settledCases && settledCases.length > Constant.zero && isLoggedIn) {
      setPaymentNotifications(settledCases, t, dispatch);
    }
  }, [settledCases]);

  const globalErrors =
    !sigmaAvailable &&
    pathname !== Url.faq &&
    pathname !== Url.unloggedContact ? null : (
      <GlobalErrors />
    );

  const routes = getAppRoutes(t).map((props: AppRoute) => (
    <Route
      element={
        <RouteGuard
          {...props}
          path={pathname.startsWith('//') ? pathname : props.path}
        />
      }
      key={props.key}
    >
      <Route path={props.path} element={props.element} />
    </Route>
  ));

  useEffect(() => {
    detectBrowser();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      webSocketsConnection();
      dispatch.user.fetchUserData();
      dispatch.statements.getStatements();
    }
  }, [isLoggedIn]);

  const detectBrowser = () => {
    const { userAgent } = navigator;
    if (userAgent.match(/iphone/i)) {
      dispatch.pwa.setIsIphone(true);
    } else {
      dispatch.pwa.setIsIphone(false);
    }
  };

  useEffect(() => {
    if (isIphone) {
      dispatch.pwa.setPWAButton(true);
    }
  }, [isIphone]);

  useEffect(() => {
    if (step || pathname !== Url.register) {
      trackVirtualPageView({ pathname, step, userId });
    }
  }, [step, pathname]);

  useEffect(() => {
    const agreementTitle = 'umowa';
    const [firstAgreementId] = agreementIds;
    const agreementIdsArrayIsEmpty = agreementIds.length === 0;

    if (isWidgetReady && isLoggedIn) {
      if (agreementIdsArrayIsEmpty) {
        (window as any).LC_API.set_custom_variables([
          { name: agreementTitle, value: null }
        ]);
      }
      if (firstAgreementId) {
        (window as any).LC_API.set_custom_variables([
          { name: agreementTitle, value: firstAgreementId }
        ]);
      }
    }
  }, [agreementIds, isWidgetReady]);

  useEffect(() => {
    if (widgetState) {
      dispatch.liveChat.setVisibility(widgetState.visibility);
    }
  }, [widgetState?.visibility]);

  return (
    <BrowserRouter>
      <MenuVisibilityProvider>
        <ScrollToTop>
          <>
            <Statements />
            <LastUrl />
            <Impersonation />
            {showLiveChatWidget && (
              <LiveChatWidget
                license={liveChatLicense}
                customerName={fullName}
                customerEmail={user?.email || tokenEmail}
                visibility={visibility}
              />
            )}
            {globalErrors}
            {WebSocketsEvents()}
            <Regulation />
            <Menu />
            <StyledContainer maxWidth="xl" disableGutters>
              <Grid
                container
                flexDirection="column"
                spacing={{ xs: 2, sm: 2, md: 2, lg: 2.5, xl: 2.5 }}
                columns={{ xs: 4, sm: 4, md: 8, lg: 12, xl: 12 }}
                sx={{ flex: 1 }}
              >
                <Grid item sx={{ display: 'flex', flex: 1 }} width="100%">
                  <LayoutContent>
                    <SentryRoutes>{routes}</SentryRoutes>
                  </LayoutContent>
                </Grid>
              </Grid>
            </StyledContainer>
            <Footer />
          </>
        </ScrollToTop>
      </MenuVisibilityProvider>
    </BrowserRouter>
  );
};

export default Layout;
