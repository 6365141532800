import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import effects from './documents.effects';
import reducers from './documents.reducers';
import { DocumentsState } from './documents.model';

const documentsModel = createModel<RootModel>()({
  state: {
    availableDocuments: [],
    sharedDocumentsForAgreement: [],
    sharedDocumentsForUser: null,
    documentsForOrder: []
  } as DocumentsState,
  reducers,
  effects
});

export default documentsModel;
