import { Dispatch, SetStateAction } from 'react';
import { Action } from '@rematch/core';
import { TFunction } from 'react-i18next';
import { Url } from 'utils/urls';
import { IsLoggedIn } from 'utils/user';

export const getMenuList = (t: TFunction<'translation'>) => ({
  primary: {
    id: 1,
    items: [
      {
        text: t('COMMON_MY_OBLIGATIONS'),
        href: Url.dashboard,
        loggedOnly: true
      },
      {
        text: t('COMMON_CONTACT'),
        href: IsLoggedIn() ? Url.loggedContact : Url.unloggedContact
      }
    ]
  },
  secondary: {
    id: 2,
    items: [
      { text: t('COMMON_MY_PROFILE'), href: Url.userProfile, loggedOnly: true },
      {
        text: t('COMMON_INFORMATION_OBLIGATION'),
        href: Url.informationObligation,
        loggedOnly: true
      },
      {
        text: t('COMMON_MARKETING_CONSENTS'),
        href: Url.marketingConsents,
        loggedOnly: true
      }
    ]
  }
});

export const getMenuFooterList = (
  t: TFunction<'translation'>,
  setMenuVisible: Dispatch<SetStateAction<boolean>>,
  modals: {
    changePrivacyPolicyVisibility: () => Action<void, void>;
    changeRegulationsVisibility: () => Action<void, void>;
  }
) => [
  { text: t('PAGE_FAQ_TITLE'), href: Url.faq },
  {
    text: t('COMMON_PRIVACY_POLICY'),
    href: Url.privacyPolicy
  },
  {
    text: t('COMMON_REGULATIONS'),
    onClick: () => {
      setMenuVisible(false);
      modals.changeRegulationsVisibility();
    }
  }
];
