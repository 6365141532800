import { SvgIcon } from '@mui/material';

const Docs = ({ ...props }) => (
  <SvgIcon viewBox="0 0 70 63" fill="none" {...props}>
    <path
      d="M21.7762 7.47327L7.11268 11.4024C6.97815 11.4381 6.83492 11.4191 6.71443 11.3494C6.59393 11.2797 6.50602 11.165 6.46999 11.0305C6.43396 10.8961 6.45276 10.7528 6.52226 10.6322C6.59175 10.5116 6.70627 10.4235 6.84067 10.3872L21.5042 6.45809C21.6388 6.42202 21.7823 6.44091 21.903 6.51059C22.0237 6.58028 22.1117 6.69505 22.1478 6.82967C22.1839 6.96429 22.165 7.10773 22.0953 7.22842C22.0256 7.34912 21.9109 7.43719 21.7762 7.47327Z"
      fill="white"
    />
    <path
      d="M19.0536 11.9509L8.04957 14.8994C7.91504 14.9352 7.77181 14.9162 7.65132 14.8465C7.53082 14.7767 7.44291 14.6621 7.40688 14.5276C7.37085 14.3931 7.38965 14.2499 7.45915 14.1293C7.52864 14.0086 7.64316 13.9205 7.77756 13.8843L18.7816 10.9357C18.9161 10.8999 19.0594 10.919 19.1799 10.9887C19.3003 11.0584 19.3883 11.1731 19.4243 11.3076C19.4603 11.442 19.4415 11.5853 19.372 11.7059C19.3025 11.8265 19.188 11.9146 19.0536 11.9509Z"
      fill="white"
    />
    <path
      d="M56.3778 48.3151L13.6279 59.7699C13.4783 59.8098 13.319 59.7887 13.1849 59.7113C13.0509 59.6339 12.953 59.5065 12.9128 59.357L0.309444 12.3208C0.26955 12.1712 0.290617 12.012 0.368018 11.8779C0.445418 11.7438 0.572833 11.646 0.722313 11.6057L43.4722 0.150909C43.6218 0.111015 43.7811 0.132077 43.9151 0.209478C44.0492 0.286878 44.1471 0.414293 44.1873 0.563773L56.7906 47.6C56.8305 47.7495 56.8095 47.9088 56.7321 48.0429C56.6547 48.1769 56.5273 48.2748 56.3778 48.3151Z"
      fill="white"
    />
    <path
      d="M56.3778 48.3151L13.6279 59.7699C13.4783 59.8098 13.319 59.7887 13.1849 59.7113C13.0509 59.6339 12.953 59.5065 12.9128 59.357L0.309444 12.3208C0.26955 12.1712 0.290617 12.012 0.368018 11.8779C0.445418 11.7438 0.572833 11.646 0.722313 11.6057L43.4722 0.150909C43.6218 0.111015 43.7811 0.132077 43.9151 0.209478C44.0492 0.286878 44.1471 0.414293 44.1873 0.563773L56.7906 47.6C56.8305 47.7495 56.8095 47.9088 56.7321 48.0429C56.6547 48.1769 56.5273 48.2748 56.3778 48.3151ZM0.782759 11.8313C0.693068 11.8554 0.616616 11.9142 0.570175 11.9946C0.523734 12.075 0.511099 12.1706 0.53504 12.2604L13.1383 59.2966C13.1625 59.3862 13.2212 59.4627 13.3017 59.5091C13.3821 59.5556 13.4777 59.5682 13.5674 59.5443L56.3173 48.0895C56.407 48.0653 56.4835 48.0066 56.5299 47.9262C56.5764 47.8457 56.589 47.7501 56.565 47.6604L43.9617 0.624215C43.9376 0.534525 43.8789 0.458074 43.7984 0.411633C43.718 0.365192 43.6224 0.352556 43.5327 0.376497L0.782759 11.8313Z"
      fill="#CCCCCC"
    />
    <path
      d="M25.9189 24.8016L10.503 28.9322C10.3683 28.9681 10.225 28.9492 10.1043 28.8795C9.98367 28.8099 9.89558 28.6952 9.85936 28.5607L7.01833 17.9578C6.98242 17.8232 7.00138 17.6798 7.07104 17.5592C7.1407 17.4385 7.25538 17.3504 7.38991 17.3142L22.8059 13.1835C22.9405 13.1476 23.0838 13.1665 23.2045 13.2362C23.3251 13.3059 23.4132 13.4205 23.4494 13.5551L26.2905 24.158C26.3264 24.2926 26.3074 24.4359 26.2378 24.5566C26.1681 24.6772 26.0534 24.7653 25.9189 24.8016Z"
      fill="#CCCCCC"
    />
    <path
      d="M45.2218 25.8562L12.0596 34.742C11.925 34.778 11.7816 34.7591 11.6609 34.6895C11.5402 34.6198 11.4521 34.505 11.416 34.3704C11.38 34.2358 11.3988 34.0923 11.4685 33.9716C11.5382 33.8509 11.653 33.7629 11.7876 33.7268L44.9498 24.841C45.0844 24.8049 45.2279 24.8238 45.3486 24.8935C45.4693 24.9632 45.5573 25.078 45.5934 25.2126C45.6295 25.3472 45.6106 25.4906 45.5409 25.6113C45.4712 25.732 45.3564 25.8201 45.2218 25.8562Z"
      fill="#CCCCCC"
    />
    <path
      d="M38.1502 31.4991L12.9965 38.239C12.8619 38.2751 12.7185 38.2562 12.5978 38.1865C12.4771 38.1168 12.389 38.0021 12.3529 37.8675C12.3169 37.7328 12.3357 37.5894 12.4054 37.4687C12.4751 37.348 12.5899 37.2599 12.7245 37.2239L37.8781 30.484C38.0128 30.4479 38.1562 30.4668 38.2769 30.5365C38.3976 30.6061 38.4857 30.7209 38.5217 30.8555C38.5578 30.9902 38.5389 31.1336 38.4692 31.2543C38.3996 31.375 38.2848 31.4631 38.1502 31.4991Z"
      fill="#CCCCCC"
    />
    <path
      d="M41.6634 13.0272L26.9998 16.9563C26.8652 16.9924 26.7218 16.9735 26.6011 16.9038C26.4804 16.8341 26.3923 16.7194 26.3562 16.5847C26.3202 16.4501 26.339 16.3067 26.4087 16.186C26.4784 16.0653 26.5932 15.9772 26.7278 15.9411L41.3914 12.0121C41.526 11.976 41.6694 11.9949 41.7901 12.0645C41.9108 12.1342 41.9989 12.249 42.0349 12.3836C42.071 12.5183 42.0521 12.6617 41.9825 12.7824C41.9128 12.9031 41.798 12.9911 41.6634 13.0272Z"
      fill="#CCCCCC"
    />
    <path
      d="M38.9412 17.5051L27.9371 20.4536C27.8026 20.4894 27.6594 20.4704 27.5389 20.4007C27.4184 20.3309 27.3305 20.2163 27.2945 20.0818C27.2584 19.9473 27.2772 19.8041 27.3467 19.6835C27.4162 19.5628 27.5307 19.4747 27.6651 19.4385L38.6692 16.4899C38.8037 16.4541 38.9469 16.4732 39.0674 16.5429C39.1879 16.6126 39.2758 16.7273 39.3119 16.8617C39.3479 16.9962 39.3291 17.1395 39.2596 17.2601C39.1901 17.3807 39.0756 17.4688 38.9412 17.5051Z"
      fill="#CCCCCC"
    />
    <path
      d="M47.0957 32.8493L13.9335 41.7351C13.7989 41.7712 13.6555 41.7523 13.5348 41.6826C13.4141 41.6129 13.326 41.4982 13.2899 41.3635C13.2539 41.2289 13.2728 41.0855 13.3424 40.9648C13.4121 40.8441 13.5269 40.756 13.6615 40.72L46.8237 31.8342C46.9583 31.7981 47.1018 31.817 47.2225 31.8867C47.3432 31.9563 47.4312 32.0711 47.4673 32.2057C47.5034 32.3404 47.4845 32.4838 47.4148 32.6045C47.3451 32.7252 47.2303 32.8133 47.0957 32.8493Z"
      fill="#CCCCCC"
    />
    <path
      d="M40.0238 38.492L14.8701 45.2319C14.7356 45.2677 14.5924 45.2487 14.4719 45.179C14.3514 45.1093 14.2635 44.9946 14.2274 44.8601C14.1914 44.7257 14.2102 44.5824 14.2797 44.4618C14.3492 44.3412 14.4637 44.253 14.5981 44.2168L39.7518 37.4769C39.8864 37.4408 40.0298 37.4597 40.1505 37.5294C40.2712 37.5991 40.3593 37.7138 40.3953 37.8485C40.4314 37.9831 40.4125 38.1265 40.3429 38.2472C40.2732 38.3679 40.1584 38.456 40.0238 38.492Z"
      fill="#CCCCCC"
    />
    <path
      d="M48.9691 39.8427L15.8069 48.7285C15.6724 48.7643 15.5291 48.7453 15.4086 48.6756C15.2881 48.6058 15.2002 48.4912 15.1642 48.3567C15.1282 48.2222 15.147 48.079 15.2165 47.9584C15.286 47.8377 15.4005 47.7496 15.5349 47.7134L48.6971 38.8276C48.8317 38.7915 48.9751 38.8104 49.0958 38.8801C49.2165 38.9497 49.3046 39.0645 49.3407 39.1991C49.3767 39.3338 49.3579 39.4772 49.2882 39.5979C49.2185 39.7186 49.1037 39.8067 48.9691 39.8427Z"
      fill="#CCCCCC"
    />
    <path
      d="M49.9064 43.3396L16.7442 52.2254C16.6096 52.2614 16.4661 52.2425 16.3454 52.1729C16.2248 52.1032 16.1367 51.9884 16.1006 51.8538C16.0645 51.7192 16.0834 51.5757 16.1531 51.455C16.2228 51.3343 16.3376 51.2463 16.4722 51.2102L49.6344 42.3244C49.769 42.2883 49.9124 42.3072 50.0331 42.3769C50.1538 42.4466 50.2419 42.5614 50.278 42.696C50.314 42.8306 50.2952 42.974 50.2255 43.0947C50.1558 43.2154 50.041 43.3035 49.9064 43.3396Z"
      fill="#CCCCCC"
    />
    <path
      d="M46.4057 13.632H31.2249C31.0857 13.6318 30.9522 13.5763 30.8539 13.4778C30.7556 13.3793 30.7003 13.2458 30.7003 13.1065C30.7003 12.9673 30.7556 12.8338 30.8539 12.7353C30.9522 12.6368 31.0857 12.5813 31.2249 12.5811H46.4057C46.5451 12.5811 46.6787 12.6364 46.7773 12.735C46.8758 12.8335 46.9312 12.9672 46.9312 13.1065C46.9312 13.2459 46.8758 13.3796 46.7773 13.4781C46.6787 13.5767 46.5451 13.632 46.4057 13.632Z"
      fill="white"
    />
    <path
      d="M42.6171 17.2526H31.2249C31.0857 17.2524 30.9522 17.1969 30.8539 17.0984C30.7556 16.9999 30.7003 16.8664 30.7003 16.7272C30.7003 16.5879 30.7556 16.4544 30.8539 16.3559C30.9522 16.2574 31.0857 16.2019 31.2249 16.2017H42.6171C42.7563 16.2019 42.8897 16.2574 42.9881 16.3559C43.0864 16.4544 43.1417 16.5879 43.1417 16.7272C43.1417 16.8664 43.0864 16.9999 42.9881 17.0984C42.8897 17.1969 42.7563 17.2524 42.6171 17.2526Z"
      fill="white"
    />
    <path
      d="M69.2578 62.0375H24.9998C24.845 62.0373 24.6966 61.9758 24.5871 61.8663C24.4776 61.7568 24.4161 61.6084 24.4159 61.4536V12.7582C24.4161 12.6034 24.4776 12.455 24.5871 12.3455C24.6966 12.2361 24.845 12.1745 24.9998 12.1743H69.2578C69.4126 12.1745 69.561 12.2361 69.6704 12.3455C69.7799 12.455 69.8415 12.6034 69.8416 12.7582V61.4536C69.8415 61.6084 69.7799 61.7568 69.6704 61.8663C69.561 61.9758 69.4126 62.0373 69.2578 62.0375Z"
      fill="white"
    />
    <path
      d="M69.2578 62.0375H24.9998C24.845 62.0373 24.6966 61.9758 24.5871 61.8663C24.4776 61.7568 24.4161 61.6084 24.4159 61.4536V12.7582C24.4161 12.6034 24.4776 12.455 24.5871 12.3455C24.6966 12.2361 24.845 12.1745 24.9998 12.1743H69.2578C69.4126 12.1745 69.561 12.2361 69.6704 12.3455C69.7799 12.455 69.8415 12.6034 69.8416 12.7582V61.4536C69.8415 61.6084 69.7799 61.7568 69.6704 61.8663C69.561 61.9758 69.4126 62.0373 69.2578 62.0375ZM24.9998 12.4079C24.9069 12.408 24.8178 12.4449 24.7522 12.5106C24.6865 12.5763 24.6496 12.6653 24.6494 12.7582V61.4536C24.6496 61.5465 24.6865 61.6356 24.7522 61.7012C24.8178 61.7669 24.9069 61.8039 24.9998 61.804H69.2578C69.3506 61.8039 69.4397 61.7669 69.5054 61.7012C69.571 61.6356 69.608 61.5465 69.6081 61.4536V12.7582C69.608 12.6653 69.571 12.5763 69.5054 12.5106C69.4397 12.4449 69.3506 12.408 69.2578 12.4079H24.9998Z"
      fill="#CCCCCC"
    />
    <path
      d="M45.9225 31.442H29.9627C29.8234 31.4418 29.6899 31.3864 29.5913 31.2879C29.4928 31.1894 29.4374 31.0558 29.4373 30.9165V19.9396C29.4374 19.8002 29.4928 19.6667 29.5913 19.5681C29.6899 19.4696 29.8234 19.4142 29.9627 19.4141H45.9225C46.0618 19.4142 46.1954 19.4696 46.2939 19.5681C46.3924 19.6667 46.4478 19.8002 46.448 19.9396V30.9165C46.4478 31.0558 46.3924 31.1894 46.2939 31.2879C46.1954 31.3864 46.0618 31.4418 45.9225 31.442Z"
      fill="#CCCCCC"
    />
    <path
      d="M64.2948 37.4567H29.9627C29.8234 37.4567 29.6897 37.4014 29.5912 37.3028C29.4926 37.2043 29.4373 37.0706 29.4373 36.9313C29.4373 36.7919 29.4926 36.6582 29.5912 36.5597C29.6897 36.4611 29.8234 36.4058 29.9627 36.4058H64.2948C64.4341 36.4058 64.5678 36.4611 64.6664 36.5597C64.7649 36.6582 64.8203 36.7919 64.8203 36.9313C64.8203 37.0706 64.7649 37.2043 64.6664 37.3028C64.5678 37.4014 64.4341 37.4567 64.2948 37.4567Z"
      fill="#CCCCCC"
    />
    <path
      d="M56.0035 41.0769H29.9625C29.8231 41.0769 29.6895 41.0215 29.5909 40.9229C29.4924 40.8244 29.437 40.6907 29.437 40.5514C29.437 40.412 29.4924 40.2783 29.5909 40.1798C29.6895 40.0812 29.8231 40.0259 29.9625 40.0259H56.0035C56.1428 40.0259 56.2765 40.0812 56.375 40.1798C56.4736 40.2783 56.529 40.412 56.529 40.5514C56.529 40.6907 56.4736 40.8244 56.375 40.9229C56.2765 41.0215 56.1428 41.0769 56.0035 41.0769Z"
      fill="#CCCCCC"
    />
    <path
      d="M64.1779 24.1438H48.997C48.8577 24.1438 48.724 24.0884 48.6255 23.9898C48.5269 23.8913 48.4716 23.7576 48.4716 23.6183C48.4716 23.4789 48.5269 23.3452 48.6255 23.2467C48.724 23.1481 48.8577 23.0928 48.997 23.0928H64.1779C64.3173 23.0928 64.4509 23.1481 64.5495 23.2467C64.648 23.3452 64.7034 23.4789 64.7034 23.6183C64.7034 23.7576 64.648 23.8913 64.5495 23.9898C64.4509 24.0884 64.3173 24.1438 64.1779 24.1438Z"
      fill="#CCCCCC"
    />
    <path
      d="M60.3896 27.7644H48.9973C48.8581 27.7641 48.7247 27.7086 48.6264 27.6101C48.528 27.5116 48.4728 27.3781 48.4728 27.2389C48.4728 27.0997 48.528 26.9661 48.6264 26.8676C48.7247 26.7691 48.8581 26.7136 48.9973 26.7134H60.3896C60.5288 26.7136 60.6622 26.7691 60.7605 26.8676C60.8589 26.9661 60.9141 27.0997 60.9141 27.2389C60.9141 27.3781 60.8589 27.5116 60.7605 27.6101C60.6622 27.7086 60.5288 27.7641 60.3896 27.7644Z"
      fill="#CCCCCC"
    />
    <path
      d="M64.2948 44.6965H29.9627C29.8234 44.6965 29.6897 44.6411 29.5912 44.5426C29.4926 44.444 29.4373 44.3104 29.4373 44.171C29.4373 44.0316 29.4926 43.898 29.5912 43.7994C29.6897 43.7009 29.8234 43.6455 29.9627 43.6455H64.2948C64.4341 43.6455 64.5678 43.7009 64.6664 43.7994C64.7649 43.898 64.8203 44.0316 64.8203 44.171C64.8203 44.3104 64.7649 44.444 64.6664 44.5426C64.5678 44.6411 64.4341 44.6965 64.2948 44.6965Z"
      fill="#CCCCCC"
    />
    <path
      d="M56.0033 48.3166H29.9623C29.8231 48.3164 29.6897 48.2609 29.5913 48.1624C29.493 48.0638 29.4377 47.9303 29.4377 47.7911C29.4377 47.6519 29.493 47.5184 29.5913 47.4199C29.6897 47.3213 29.8231 47.2659 29.9623 47.2656H56.0033C56.1426 47.2656 56.2763 47.321 56.3749 47.4195C56.4734 47.5181 56.5288 47.6517 56.5288 47.7911C56.5288 47.9305 56.4734 48.0641 56.3749 48.1627C56.2763 48.2612 56.1426 48.3166 56.0033 48.3166Z"
      fill="#CCCCCC"
    />
    <path
      d="M64.2943 51.9367H29.9623C29.8231 51.9365 29.6897 51.881 29.5913 51.7825C29.493 51.684 29.4377 51.5504 29.4377 51.4112C29.4377 51.272 29.493 51.1385 29.5913 51.04C29.6897 50.9415 29.8231 50.886 29.9623 50.8857H64.2943C64.4337 50.8857 64.5674 50.9411 64.6659 51.0397C64.7645 51.1382 64.8198 51.2719 64.8198 51.4112C64.8198 51.5506 64.7645 51.6843 64.6659 51.7828C64.5674 51.8814 64.4337 51.9367 64.2943 51.9367Z"
      fill="#CCCCCC"
    />
    <path
      d="M64.2948 55.5568H29.9627C29.8234 55.5568 29.6897 55.5015 29.5912 55.4029C29.4926 55.3044 29.4373 55.1707 29.4373 55.0313C29.4373 54.892 29.4926 54.7583 29.5912 54.6598C29.6897 54.5612 29.8234 54.5059 29.9627 54.5059H64.2948C64.4341 54.5059 64.5678 54.5612 64.6664 54.6598C64.7649 54.7583 64.8203 54.892 64.8203 55.0313C64.8203 55.1707 64.7649 55.3044 64.6664 55.4029C64.5678 55.5015 64.4341 55.5568 64.2948 55.5568Z"
      fill="#CCCCCC"
    />
  </SvgIcon>
);

export default Docs;
