import {
  EodInfoStatus,
  EodInfoInLocalStorageState,
  EodName
} from './updateEodInfoInLocalStorage';

export const getEodInfoFromLocalStorage: () => EodInfoInLocalStorageState =
  () => {
    const info = localStorage.getItem(EodName);
    if (!info) {
      return { status: EodInfoStatus.NOT_DISPLAYED, date: '' };
    }
    const eodInfo = JSON.parse(info);
    return {
      status: eodInfo.status,
      date: eodInfo.date
    };
  };
