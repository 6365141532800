import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SharedDocumentForUser } from 'storage/documents/documents.model';
import { Url } from 'utils/urls';
import { Constant } from 'utils/consts';
import checkNotificationFreshness from 'utils/checkNotificationFreshness';
import MenuItem from '../../styled/MenuItem';
import { LinkStyled } from '../../styled/LinkStyled';
import NotificationItemWrapper from '../../styled/NotificationItemWrapper';
import NotificationItem from './NotificationItem';
import { DesktopMenuAnchorContext } from '../../utils/desktopMenuAnchorContext';
import { MenuVisibilityContext } from '../../utils/menuVisibilityContext';

const SharedDocuments = ({
  sharedDocuments
}: {
  sharedDocuments: SharedDocumentForUser[];
}) => {
  const { t } = useTranslation();
  const [clickedDocument, setClickedDocument] = useState<number | null>(null);
  const { setNotificationAnchor } = useContext(DesktopMenuAnchorContext);
  const { setNotificationsVisible } = useContext(MenuVisibilityContext);

  const onItemClick = (barcode: string) => {
    setNotificationsVisible!(false);
    setNotificationAnchor!(null);
    setClickedDocument(+barcode);
    let arr = [+barcode];
    const clickedDocuments = localStorage.getItem('sharedDocuments');
    localStorage.setItem('documentsToHighlight', JSON.stringify(arr));
    if (clickedDocuments) {
      const documentsArr = JSON.parse(clickedDocuments);
      if (!documentsArr.includes(+barcode)) {
        arr = [...arr, ...documentsArr];
      }
      if (documentsArr.includes(+barcode)) {
        arr = [...documentsArr];
      }
    }
    localStorage.setItem('sharedDocuments', JSON.stringify(arr));
  };

  return (
    <>
      {sharedDocuments.map(
        ({ agreementId, barcode, displayName, onDashboard }, index) => (
          <MenuItem
            onClick={() => {
              onItemClick(barcode);
            }}
            key={barcode}
          >
            <LinkStyled
              to={`${Url.case}/${agreementId}#documents`}
              data-link
              $isNotificationLink
            >
              <NotificationItemWrapper>
                <NotificationItem
                  icon="doc"
                  color="success"
                  text={`${t(
                    'NOTIFICATIONS_SHARED_DOCUMENTS_TEXT'
                  )}${displayName}`}
                  linkText={t('NOTIFICATIONS_LINK_CHECK')}
                  fontWeight={
                    checkNotificationFreshness(
                      onDashboard,
                      Number(barcode),
                      'sharedDocuments'
                    ) && clickedDocument !== +barcode
                      ? Constant.sevenHundred
                      : Constant.sixHundred
                  }
                  withoutBorder={
                    index === sharedDocuments.length - Constant.one
                  }
                />
              </NotificationItemWrapper>
            </LinkStyled>
          </MenuItem>
        )
      )}
    </>
  );
};

export default SharedDocuments;
