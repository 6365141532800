import { createModel } from '@rematch/core';
import { RootModel } from '../root';
import effects from './pwa.effects';
import reducers from './pwa.reducer';
import { PWAState } from './pwa.model';

const pwaModel = createModel<RootModel>()({
  state: {
    showButton: false,
    prompt: null,
    isIphone: false
  } as PWAState,
  effects,
  reducers
});

export default pwaModel;
