import { SvgIcon } from '@mui/material';

const OrderDoc = ({ ...props }) => (
  <SvgIcon {...props} viewBox="0 0 17 20" fill="none">
    <path
      d="M16.5 6V18C16.5 18.3978 16.342 18.7794 16.0607 19.0607C15.7794 19.342 15.3978 19.5 15 19.5H7.5C7.30109 19.5 7.11032 19.421 6.96967 19.2803C6.82902 19.1397 6.75 18.9489 6.75 18.75C6.75 18.5511 6.82902 18.3603 6.96967 18.2197C7.11032 18.079 7.30109 18 7.5 18H15V6.75H10.5C10.3011 6.75 10.1103 6.67098 9.96967 6.53033C9.82902 6.38968 9.75 6.19891 9.75 6V1.5H1.5V8.25C1.5 8.44891 1.42098 8.63968 1.28033 8.78033C1.13968 8.92098 0.948912 9 0.75 9C0.551088 9 0.360322 8.92098 0.21967 8.78033C0.0790178 8.63968 0 8.44891 0 8.25V1.5C0 1.10218 0.158035 0.720645 0.43934 0.43934C0.720644 0.158035 1.10218 2.31899e-07 1.5 2.31899e-07H10.5C10.5985 -7.72394e-05 10.6961 0.0192569 10.7871 0.0568979C10.8782 0.094539 10.9609 0.149749 11.0306 0.219375L16.2806 5.46938C16.3503 5.53908 16.4055 5.62182 16.4431 5.71286C16.4807 5.80391 16.5001 5.90148 16.5 6ZM11.25 5.25H13.9397L11.25 2.56031V5.25Z"
      fill="#006D48"
    />
    <path
      d="M7 14.5C7 14.7321 6.90781 14.9546 6.74372 15.1187C6.57962 15.2828 6.35706 15.375 6.125 15.375H4.375V17.125C4.375 17.3571 4.28281 17.5796 4.11872 17.7437C3.95462 17.9078 3.73206 18 3.5 18C3.26794 18 3.04538 17.9078 2.88128 17.7437C2.71719 17.5796 2.625 17.3571 2.625 17.125V15.375H0.875C0.642936 15.375 0.420376 15.2828 0.256282 15.1187C0.0921877 14.9546 0 14.7321 0 14.5C0 14.2679 0.0921877 14.0454 0.256282 13.8813C0.420376 13.7172 0.642936 13.625 0.875 13.625H2.625V11.875C2.625 11.6429 2.71719 11.4204 2.88128 11.2563C3.04538 11.0922 3.26794 11 3.5 11C3.73206 11 3.95462 11.0922 4.11872 11.2563C4.28281 11.4204 4.375 11.6429 4.375 11.875V13.625H6.125C6.35706 13.625 6.57962 13.7172 6.74372 13.8813C6.90781 14.0454 7 14.2679 7 14.5Z"
      fill="#006D48"
    />
  </SvgIcon>
);

export default OrderDoc;
