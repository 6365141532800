import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import getColor, { Color } from 'utils/getColor';

export const NavLinkStyled = styled(NavLink)<{
  $color?: Color;
  $isNotificationLink?: boolean;
}>`
  text-decoration: none;
  ${({ $color }) => $color && `color: ${getColor($color)};`}
  ${({ $isNotificationLink }) => $isNotificationLink && `&&& { padding: 0; }`}

  &::after {
    content: '';
    transition: width 0.2s;
    ${({ $color }) => $color && `background: ${getColor($color)};`}
    display: block;
    height: 2px;
    width: 0;
    padding-top: 2px;
  }
  &.active::after {
    width: 100%;
  }
`;
