import { ApplicationState, EodStateResponse } from './application.model';

const reducers = {
  storeAppVersion: (
    state: ApplicationState,
    bffVersion: string,
    adapterVersion: string
  ) => ({
    ...state,
    bffVersion,
    adapterVersion
  }),
  storeSigmaAvailability: (
    state: ApplicationState,
    sigmaAvailable: boolean
  ) => ({
    ...state,
    sigmaAvailable
  }),
  storeEodStatus: (state: ApplicationState, eodState: EodStateResponse) => ({
    ...state,
    eodState: { status: eodState.eodProcessing, date: eodState.businessDate }
  })
};

export default reducers;
