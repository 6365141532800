import { SvgIcon } from '@mui/material';

const FileGrey = ({ ...props }) => (
  <SvgIcon {...props} viewBox="0 0 32 35" fill="none">
    <path
      d="M14.0385 3.93096H4.34693C4.25806 3.9308 4.17289 3.89539 4.1101 3.83249C4.04732 3.76959 4.01205 3.68436 4.01205 3.59548C4.01205 3.50661 4.04732 3.42138 4.1101 3.35848C4.17289 3.29558 4.25806 3.26017 4.34693 3.26001H14.0385C14.1274 3.26001 14.2128 3.29535 14.2757 3.35827C14.3386 3.42118 14.3739 3.50651 14.3739 3.59548C14.3739 3.68446 14.3386 3.76979 14.2757 3.8327C14.2128 3.89562 14.1274 3.93096 14.0385 3.93096Z"
      fill="white"
    />
    <path
      d="M11.6198 6.242H4.34693C4.25806 6.24184 4.17289 6.20643 4.1101 6.14353C4.04732 6.08063 4.01205 5.99539 4.01205 5.90652C4.01205 5.81765 4.04732 5.73241 4.1101 5.66952C4.17289 5.60662 4.25806 5.5712 4.34693 5.57104H11.6198C11.7087 5.5712 11.7938 5.60662 11.8566 5.66952C11.9194 5.73241 11.9547 5.81765 11.9547 5.90652C11.9547 5.99539 11.9194 6.08063 11.8566 6.14353C11.7938 6.20643 11.7087 6.24184 11.6198 6.242Z"
      fill="white"
    />
    <path
      d="M28.6273 34.8329H0.372753C0.273928 34.8328 0.179184 34.7935 0.109304 34.7236C0.0394243 34.6537 0.0001131 34.559 0 34.4601V3.37275C0.0001131 3.27393 0.0394243 3.17918 0.109304 3.1093C0.179184 3.03942 0.273928 3.00011 0.372753 3H28.6273C28.7261 3.00011 28.8208 3.03942 28.8907 3.1093C28.9606 3.17918 28.9999 3.27393 29 3.37275V34.4601C28.9999 34.559 28.9606 34.6537 28.8907 34.7236C28.8208 34.7935 28.7261 34.8328 28.6273 34.8329Z"
      fill="white"
    />
    <path
      d="M28.6273 34.8329H0.372753C0.273928 34.8328 0.179184 34.7935 0.109304 34.7236C0.0394243 34.6537 0.0001131 34.559 0 34.4601V3.37275C0.0001131 3.27393 0.0394243 3.17918 0.109304 3.1093C0.179184 3.03942 0.273928 3.00011 0.372753 3H28.6273C28.7261 3.00011 28.8208 3.03942 28.8907 3.1093C28.9606 3.17918 28.9999 3.27393 29 3.37275V34.4601C28.9999 34.559 28.9606 34.6537 28.8907 34.7236C28.8208 34.7935 28.7261 34.8328 28.6273 34.8329ZM0.372753 3.1491C0.313457 3.14916 0.256609 3.17275 0.21468 3.21467C0.172751 3.2566 0.149168 3.31345 0.149103 3.37275V34.4601C0.149168 34.5194 0.172751 34.5763 0.21468 34.6182C0.256609 34.6601 0.313458 34.6837 0.372753 34.6838H28.6273C28.6865 34.6837 28.7434 34.6601 28.7853 34.6182C28.8272 34.5763 28.8508 34.5194 28.8509 34.4601V3.37275C28.8508 3.31345 28.8272 3.2566 28.7853 3.21467C28.7434 3.17275 28.6865 3.14916 28.6273 3.1491H0.372753Z"
      fill="#CCCCCC"
    />
    <path
      d="M13.7299 15.3007H3.54112C3.45218 15.3006 3.3669 15.2653 3.30401 15.2024C3.24112 15.1395 3.20574 15.0542 3.20564 14.9653V7.95755C3.20574 7.8686 3.24112 7.78333 3.30401 7.72044C3.3669 7.65755 3.45217 7.62217 3.54112 7.62207H13.7299C13.8188 7.62217 13.9041 7.65755 13.967 7.72044C14.0299 7.78333 14.0653 7.8686 14.0654 7.95755V14.9653C14.0653 15.0542 14.0299 15.1395 13.967 15.2024C13.9041 15.2653 13.8188 15.3006 13.7299 15.3007Z"
      fill="#CCCCCC"
    />
    <path
      d="M25.4588 19.1402H3.54112C3.45214 19.1402 3.36682 19.1048 3.3039 19.0419C3.24099 18.979 3.20564 18.8937 3.20564 18.8047C3.20564 18.7157 3.24099 18.6304 3.3039 18.5675C3.36682 18.5046 3.45214 18.4692 3.54112 18.4692H25.4588C25.5478 18.4692 25.6332 18.5046 25.6961 18.5675C25.759 18.6304 25.7943 18.7157 25.7943 18.8047C25.7943 18.8937 25.759 18.979 25.6961 19.0419C25.6332 19.1048 25.5478 19.1402 25.4588 19.1402Z"
      fill="#CCCCCC"
    />
    <path
      d="M20.1657 21.4512H3.54104C3.45207 21.4512 3.36674 21.4159 3.30382 21.353C3.24091 21.2901 3.20557 21.2047 3.20557 21.1158C3.20557 21.0268 3.24091 20.9414 3.30382 20.8785C3.36674 20.8156 3.45207 20.7803 3.54104 20.7803H20.1657C20.2547 20.7803 20.34 20.8156 20.4029 20.8785C20.4658 20.9414 20.5012 21.0268 20.5012 21.1158C20.5012 21.2047 20.4658 21.2901 20.4029 21.353C20.34 21.4159 20.2547 21.4512 20.1657 21.4512Z"
      fill="#CCCCCC"
    />
    <path
      d="M25.3842 10.6414H15.6927C15.6038 10.6414 15.5184 10.6061 15.4555 10.5431C15.3926 10.4802 15.3573 10.3949 15.3573 10.3059C15.3573 10.217 15.3926 10.1316 15.4555 10.0687C15.5184 10.0058 15.6038 9.97046 15.6927 9.97046H25.3842C25.4732 9.97046 25.5585 10.0058 25.6215 10.0687C25.6844 10.1316 25.7197 10.217 25.7197 10.3059C25.7197 10.3949 25.6844 10.4802 25.6215 10.5431C25.5585 10.6061 25.4732 10.6414 25.3842 10.6414Z"
      fill="#CCCCCC"
    />
    <path
      d="M22.9657 12.9524H15.6929C15.604 12.9523 15.5188 12.9169 15.4561 12.854C15.3933 12.7911 15.358 12.7058 15.358 12.617C15.358 12.5281 15.3933 12.4429 15.4561 12.38C15.5188 12.3171 15.604 12.2817 15.6929 12.2815H22.9657C23.0546 12.2817 23.1398 12.3171 23.2026 12.38C23.2653 12.4429 23.3006 12.5281 23.3006 12.617C23.3006 12.7058 23.2653 12.7911 23.2026 12.854C23.1398 12.9169 23.0546 12.9523 22.9657 12.9524Z"
      fill="#CCCCCC"
    />
    <path
      d="M25.4588 23.7623H3.54112C3.45214 23.7623 3.36682 23.7269 3.3039 23.664C3.24099 23.6011 3.20564 23.5158 3.20564 23.4268C3.20564 23.3378 3.24099 23.2525 3.3039 23.1896C3.36682 23.1267 3.45214 23.0913 3.54112 23.0913H25.4588C25.5478 23.0913 25.6332 23.1267 25.6961 23.1896C25.759 23.2525 25.7943 23.3378 25.7943 23.4268C25.7943 23.5158 25.759 23.6011 25.6961 23.664C25.6332 23.7269 25.5478 23.7623 25.4588 23.7623Z"
      fill="#CCCCCC"
    />
    <path
      d="M20.1656 26.0733H3.54089C3.45202 26.0731 3.36684 26.0377 3.30406 25.9748C3.24127 25.9119 3.20601 25.8267 3.20601 25.7378C3.20601 25.6489 3.24127 25.5637 3.30406 25.5008C3.36684 25.4379 3.45202 25.4025 3.54089 25.4023H20.1656C20.2545 25.4023 20.3399 25.4377 20.4028 25.5006C20.4657 25.5635 20.501 25.6488 20.501 25.7378C20.501 25.8268 20.4657 25.9121 20.4028 25.975C20.3399 26.038 20.2545 26.0733 20.1656 26.0733Z"
      fill="#CCCCCC"
    />
    <path
      d="M25.4586 28.3843H3.54089C3.45202 28.3842 3.36684 28.3488 3.30406 28.2859C3.24127 28.223 3.20601 28.1377 3.20601 28.0489C3.20601 27.96 3.24127 27.8747 3.30406 27.8118C3.36684 27.7489 3.45202 27.7135 3.54089 27.7134H25.4586C25.5476 27.7134 25.6329 27.7487 25.6958 27.8116C25.7588 27.8745 25.7941 27.9599 25.7941 28.0489C25.7941 28.1378 25.7588 28.2232 25.6958 28.2861C25.6329 28.349 25.5476 28.3843 25.4586 28.3843Z"
      fill="#CCCCCC"
    />
    <path
      d="M25.4588 30.6954H3.54112C3.45214 30.6954 3.36682 30.66 3.3039 30.5971C3.24099 30.5342 3.20564 30.4489 3.20564 30.3599C3.20564 30.2709 3.24099 30.1856 3.3039 30.1227C3.36682 30.0598 3.45214 30.0244 3.54112 30.0244H25.4588C25.5478 30.0244 25.6332 30.0598 25.6961 30.1227C25.759 30.1856 25.7943 30.2709 25.7943 30.3599C25.7943 30.4489 25.759 30.5342 25.6961 30.5971C25.6332 30.66 25.5478 30.6954 25.4588 30.6954Z"
      fill="#CCCCCC"
    />
    <path
      d="M28.3648 7.34376C26.6462 7.96666 24.7487 7.08013 24.1265 5.36366C23.5044 3.64718 24.3933 1.75075 26.1119 1.12785C27.8304 0.504956 29.728 1.39148 30.3501 3.10795C30.9722 4.82443 30.0834 6.72087 28.3648 7.34376Z"
      fill="#CCCCCC"
    />
  </SvgIcon>
);

export default FileGrey;
