import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useWidgetState } from '@livechat/widget-react';
import { RootState, RootDispatch } from 'storage/store';
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  useMediaQuery
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import theme from 'App.theme';
import {
  AvailabilityStatus,
  VisibilityStatus
} from 'storage/liveChat/liveChat.model';
import { Icon } from 'components/Icon';
import { footerClickEvent } from 'utils/events';
import { IsLoggedIn } from 'utils/user';
import sendEvent from 'utils/sendEvent';
import { Url } from 'utils/urls';
import ContactWrapper from './styled/ContactWrapper';
import FooterWrapper from './styled/FooterWrapper';
import pjson from '../../../package.json';

const Footer = () => {
  const { modals, liveChat } = useDispatch<RootDispatch>();
  const { t } = useTranslation();
  const widgetState = useWidgetState();
  const phoneNumber = t('COMMON_PHONE_NUMBER');
  const { application } = useDispatch<RootDispatch>();
  const applicationVersion = useSelector(
    (state: RootState) => state.application
  );
  const { bffVersion, adapterVersion } = applicationVersion;
  const fedVersion = pjson?.version;
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const widgetIsAvailable =
    IsLoggedIn() &&
    widgetState &&
    widgetState.availability === AvailabilityStatus.online;

  useEffect(() => {
    loadApplicationVersion();
  }, []);

  const loadApplicationVersion = async () => {
    await application.getApplicationVersion();
  };

  const handleClick = () => {
    modals.changeRegulationsVisibility();
    sendEvent({
      labelText: t('COMMON_REGULATIONS'),
      ...footerClickEvent
    });
  };

  return (
    <footer>
      <FooterWrapper
        theme={theme}
        sx={
          widgetIsAvailable
            ? {
                [theme.breakpoints.down('md')]: {
                  minHeight: '146px'
                },
                [theme.breakpoints.down('lg')]: {
                  minHeight: '126px'
                }
              }
            : null
        }
      >
        <Container maxWidth="xl">
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              sx={{
                minHeight: '100%',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  [theme.breakpoints.up('md')]: {
                    marginLeft: '-22px'
                  }
                }}
              >
                <Icon
                  icon="phone"
                  sx={{
                    marginRight: '21px',
                    marginTop: '6px',
                    color: 'primary.light'
                  }}
                  fontSize="medium"
                />
                <ContactWrapper theme={theme}>
                  <Typography
                    variant="body1"
                    sx={{
                      [theme.breakpoints.down('md')]: {
                        marginBottom: '4px'
                      }
                    }}
                  >
                    {t('FOOTER_CONTACT_NAME')}&nbsp;
                    <Link
                      href={`tel:${phoneNumber.replace(/ /g, '')}`}
                      onClick={() =>
                        sendEvent({
                          labelText: phoneNumber,
                          ...footerClickEvent
                        })
                      }
                      data-link-no-alert
                    >
                      {phoneNumber}
                    </Link>
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      [theme.breakpoints.down('md')]: {
                        flexFlow: 'column'
                      }
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 400 }}>
                      {t(
                        mobileBreakpoint
                          ? 'FOOTER_CONTACT_WORKWEEKHOURS_MOBILE'
                          : 'FOOTER_CONTACT_WORKWEEKHOURS'
                      )}
                      &nbsp;
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 400 }}>
                      {t('FOOTER_CONTACT_WEEKENDHOURS')}
                    </Typography>
                  </Box>
                </ContactWrapper>
              </Box>
              {widgetIsAvailable && (
                <Box
                  sx={{
                    display: 'flex',
                    marginTop: '15px',
                    [theme.breakpoints.up('md')]: {
                      marginLeft: '-22px'
                    }
                  }}
                >
                  <Icon icon="chat" color="success" />
                  <Grid
                    item
                    xs={11}
                    sx={{
                      paddingBottom: '6px',
                      marginLeft: '21px',
                      marginTop: '-3px'
                    }}
                  >
                    <Link
                      sx={{
                        fontWeight: 700,
                        lineHeight: '125%',
                        cursor: 'pointer'
                      }}
                      color="primary.light"
                      onClick={() =>
                        liveChat.setVisibility(VisibilityStatus.maximized)
                      }
                    >
                      {t('CHAT_CONVERSATION')}
                    </Link>
                  </Grid>
                </Box>
              )}
            </Grid>
            <Grid
              item
              lg={6}
              sx={{
                display: { xs: 'none', lg: 'flex' },
                alignItems: 'center',
                justifyContent: 'space-evenly',
                marginLeft: '-40px'
              }}
            >
              <Link
                variant="subtitle1"
                to={Url.privacyPolicy}
                component={NavLink}
                onClick={() =>
                  sendEvent({
                    labelText: t('COMMON_PRIVACY_POLICY'),
                    ...footerClickEvent
                  })
                }
              >
                {t('COMMON_PRIVACY_POLICY')}
              </Link>
              <Link
                variant="subtitle1"
                component="button"
                onClick={handleClick}
              >
                {t('COMMON_REGULATIONS')}
              </Link>
              <Link
                variant="subtitle1"
                to={Url.faq}
                component={NavLink}
                onClick={() => {
                  sendEvent({
                    labelText: t('FOOTER_FAQ_PAGE'),
                    ...footerClickEvent
                  });
                }}
              >
                {t('FOOTER_FAQ_PAGE')}
              </Link>
            </Grid>
          </Grid>
          <Grid
            sx={{
              marginLeft: '-22px',
              [theme.breakpoints.down('lg')]: {
                display: 'none'
              }
            }}
          >
            <Typography
              variant="body2"
              margin="5px 0px 10px"
              color="common.white"
            >
              front: {fedVersion} | bff: {bffVersion} | adapter:{' '}
              {adapterVersion}
            </Typography>
          </Grid>
        </Container>
      </FooterWrapper>
    </footer>
  );
};
export default Footer;
